module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-3417998-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../plugins/directus-locale/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","url":" https://mamf-www-directus.moveapp.org/","auth":{"token":"0x4eBMSfRfoYcYl0lsth9ZSSLtzaOaoFDM0BAbyO"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":null,"languages":["en","pl"],"redirect":false,"defaultLanguage":"en","siteUrl":"https://mamf.pl/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/case-studies/:slug","getLanguageFromPath":true,"excludeLanguages":["en","pl"]},{"matchPath":"/:langOrSlug?","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e683e3ac618b12541aef4a228687facd"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
