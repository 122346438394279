// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-case-studies-tsx": () => import("./../../../src/pages/case-studies.tsx" /* webpackChunkName: "component---src-pages-case-studies-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-investor-relations-tsx": () => import("./../../../src/pages/investor-relations.tsx" /* webpackChunkName: "component---src-pages-investor-relations-tsx" */),
  "component---src-pages-iso-tsx": () => import("./../../../src/pages/iso.tsx" /* webpackChunkName: "component---src-pages-iso-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-rosa-tsx": () => import("./../../../src/pages/rosa.tsx" /* webpackChunkName: "component---src-pages-rosa-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-sport-tsx": () => import("./../../../src/pages/sport.tsx" /* webpackChunkName: "component---src-pages-sport-tsx" */),
  "component---src-pages-technology-radar-tsx": () => import("./../../../src/pages/technology-radar.tsx" /* webpackChunkName: "component---src-pages-technology-radar-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/case-study.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */)
}

